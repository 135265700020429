import React from "react"
import { Link, graphql } from "gatsby"
import { FaCalendarAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/style.css"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header>
                <h3 className="text-2xl font-black mb-2 font-header">
                  <Link
                    className="text-lead shadow-none"
                    to={node.fields.slug}
                  >
                    {title}
                  </Link>
                </h3>
                <div class="text-sm font-textz font-semibold align-middle">
                  {node.frontmatter.date}
                </div>
              </header>
              <section>
                <p
                  className="mb-8 font-textz pt-4 font-light"
                  dangerouslySetInnerHTML={{
                    __html:  node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {live: {eq: true}}}, 
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
